import React, { useState } from 'react'
import Navbar from '../../components/navbar/navbar'
import './home.css';
import Footer from '../../components/footer/footer';
import Body from '../../components/body/body';

const Home = () => {
  var [type, setType] = useState("student");

  return (
    <div className='home'>
        <Navbar type={type} setType={setType} />

        <Body type={type} setType={setType} />

        <Footer />
    </div>
  )
}

export default Home