import React, { useState } from 'react';
import './navbar.css';
import logo from '../../images/logo.png';

const Navbar = ({ type, setType }) => {
    
  return (
    <div className='navbar'>
        <div className='navbar__left'>
            <img src={logo} alt="klc" />
        </div>

        {/*<div className='navbar__right'>

            {
                type == "student" &&
                <button onClick={() => setType("teacher")} >
                    Register As Teacher
                </button>
            }

            {
                type == "teacher" &&
                <button onClick={() => setType("student")} >
                    Register As Student
                </button>
            }
        </div>*/}
    </div>
  )
}

export default Navbar