import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home/home';
import StudentSuccess from './pages/studentSuccess/studentSuccess';
import TeacherSuccess from './pages/teacherSuccess/teacherSuccess';

function App() {
  return (
    <div>
      <Router>
        <Routes>

          <Route exact path="/" element={<Home />} />

          <Route exact path="/register/student" element={<StudentSuccess />} />

          <Route exact path="/register/teacher" element={<TeacherSuccess />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
