import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineRollback } from 'react-icons/ai';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";
import './student.css';

const Student = () => {
  const navigate = useNavigate();

  var [section, setSection] = useState("parent");

  // MDB Parent Values
  var [parentNameFirst, setParentNameFirst] = useState("");
  var [parentNameLast, setParentNameLast] = useState("");
  var [email, setEmail] = useState("");
  var [phoneNumber, setPhoneNumber] = useState("");
  var [streetAddress, setStreetAddress] = useState("");
  var [city, setCity] = useState("");
  var [stateProvince, setStateProvince] = useState("");
  var [country, setCountry] = useState("United States");

  // MDB Child Values
  var [childNameFirst, setChildNameFirst] = useState("");
  var [childNameLast, setChildNameLast] = useState("");
  var [childAge, setChildAge] = useState("7");
  var [password, setPassword] = useState("");
  var [confirmPassword, setConfirmPassword] = useState("");;
  var [passwordBar, setPasswordBar] = useState("red")

  var [terms, setTerms] = useState(false);
  var [updates, setUpdates] = useState(false);

  var [loading, setLoading] = useState(false);

  // Captacha
  /*const [captchaValue, setCaptchaValue] = useState(null);



  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };*/

  const [token, setToken] = useState(null);

  const handleTokenChange = (token) => {
      setToken(token);
  };




  const submitStudentRegistration = async (e) => {
    e.preventDefault()

    if (terms === false || updates === false) {
        setLoading(false);
        toast.error("Please agree to terms and opt in to updates to sign up!")
    } else {
        if (password === confirmPassword) {
            if 
            (
                parentNameFirst !== "" &&
                parentNameLast !== "" &&
                email !== "" &&
                phoneNumber !== "" &&
                streetAddress !== "" &&
                city !== "" &&
                stateProvince !== "" &&
                country !== "" &&
                childNameFirst !== "" &&
                childNameLast !== "" &&
                childAge !== "" &&
                password !== "" &&
                confirmPassword !== ""
            ) {
                await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
                    parentNameFirst,
                    parentNameLast,
                    email,
                    phoneNumber,
                    streetAddress,
                    city,
                    stateProvince,
                    country,
                    childNameFirst,
                    childNameLast,
                    childAge,
                    password,
                    role: "student",
                    agreedToTerms: terms,
                    optInForMessaging: updates
                }).then((res) => {
                    console.log(res)

                    axios.post(`${process.env.REACT_APP_API_URL}/add-cirriculum`, {
                        studentID: res.data?.userInfo?._id
                    }).then((res) => {
                        setLoading(false);
                        navigate("/register/student")
                    }).catch((err) => {
                        console.log(err)
                        setLoading(false);
                        toast.error("Fill Out All Fields Correctly!")
                    })
                    

                    
                }).catch((err) => {
                    console.log(err)
                    setLoading(false);
                    toast.error("Fill Out All Fields Correctly!")
                })
            } else {
                setLoading(false);
                toast.error("Fill Out All Fields!")
            }
        } else {
            setLoading(false);
            toast.error("Passwords Do Not Match!")
        }
    }




  }



  const handleSubmitCaptcha = async (e) => {
    e.preventDefault()
    setLoading(true);
    // Trigger reCAPTCHA check
    const captchaToken = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' });

    console.log(captchaToken)
    
    /*if (!captchaToken) {
        console.error("Failed to get the reCAPTCHA token.");
        return;
    }*/



    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/verify-captcha-v3`, {
            userResponseToken: captchaToken
        });

        console.log(response)

        if (response.data.success) {
            // Handle successful verification. Maybe show a success message or redirect.
            console.log(response.data.success)
            submitStudentRegistration(e)
        } else {
            // Handle failed verification.
            setLoading(false);
            console.error("Captcha verification failed.");
            toast.error("Captcha verification failed.")
        }
    } catch (error) {
        setLoading(false);
        console.error("Error submitting form");
        toast.error("Captcha verification failed.")
    }
  };


  useEffect(() => {
    if (password === confirmPassword && password != "" && confirmPassword != "") {
        setPasswordBar("green")
    }

    if (password != confirmPassword) {
        setPasswordBar("red")
    }
  }, [password, confirmPassword])



  return (
    <>
    <ToastContainer />

        <div style={loading===true ? { display:"flex" } : { display:"none" }} className='loading__container'>
            <div class="spinner"></div>
        </div>
        
        <div style={loading===false ? { display:"flex" } : { display:"none" }} className='student__form'>
        
        <div className='form__top__container'>
            <div className='top'></div>

            <h1>
                Student Registration
            </h1>

            <h3 className='note'>
                Registering multiple students? Please fill out the form multiple times, using a different email for each student registration. Thank you🎈
            </h3>


                <div className={`parent__info ${section !== 'parent' ? 'hidden' : ''}`}>
                    <div className='input__row'>
                        <input onChange={(e)=>setParentNameFirst(e.target.value)} placeholder='Parent First Name' type="text" />

                        <input onChange={(e)=>setParentNameLast(e.target.value)} placeholder='Parent Last Name' type="text" />
                    </div>

                    <input onChange={(e)=>setEmail(e.target.value)} placeholder='Email Address' type="text" />

                    <input onChange={(e)=>setPhoneNumber(e.target.value)} placeholder='Phone Number' type="text" />

                    <input onChange={(e)=>setStreetAddress(e.target.value)} placeholder='Street Address' type="text" />

                    <div className='input__row'>
                        <input onChange={(e)=>setCity(e.target.value)} placeholder='City' type="text" />

                        <input onChange={(e)=>setStateProvince(e.target.value)} placeholder='State/Province' type="text" />
                    </div>

                    <div className='country__container'>
                        <p>
                            Country:
                        </p>
                        <select value={country} onChange={(e)=>setCountry(e.target.value)} >
                            <option value={"United States"}>
                                United States
                            </option>

                            <option value={"United Kingdom"}>
                                United Kingdom
                            </option>

                            <option value={"Canada"}>
                                Canada
                            </option>
                        </select>
                    </div>
                </div>



                <div className={`student__info ${section !== 'student' ? 'hidden' : ''}`}>
                    <div className='input__row'>
                        <input onChange={(e)=>setChildNameFirst(e.target.value)} placeholder='Student First Name' type="text" />

                        <input onChange={(e)=>setChildNameLast(e.target.value)} placeholder='Student Last Name' type="text" />
                    </div>

                    <div className='password__container'>
                        <p>Password must contain at least 6 characters and a number</p>
                        <input onChange={(e)=>setPassword(e.target.value)} placeholder='Account Password' type="text" />
                        <div style={{ backgroundColor: passwordBar }}  className='password__bar'></div>
                    </div>
                    
                    <div className='password__container'>
                        <input onChange={(e)=>setConfirmPassword(e.target.value)} placeholder='Confirm Password' type="text" />
                        <div style={{ backgroundColor: passwordBar }} className='password__bar'></div>
                    </div>

                    <div className='age__container'>
                        <p>
                            Student Age:
                        </p>
                        <select value={childAge} onChange={(e)=>setChildAge(e.target.value)} >
                            <option value={"7"} >
                                7
                            </option>

                            <option value={"8"} >
                                8
                            </option>

                            <option value={"9"} >
                                9
                            </option>

                            <option value={"10"} >
                                10
                            </option>

                            <option value={"11"} >
                                11
                            </option>

                            <option value={"12"} >
                                12
                            </option>

                            <option value={"13"} >
                                13
                            </option>

                            <option value={"14"} >
                                14
                            </option>

                            <option value={"15"} >
                                15
                            </option>

                            <option value={"16"} >
                                16
                            </option>

                            <option value={"17"} >
                                17
                            </option>
                        </select>
                    </div>


                    <div className='checkbox__container'>
                        {/*<input type="checkbox" value={terms} onClick={()=>setTerms(!terms)} />*/}
                        <input 
                            type="checkbox" 
                            checked={terms} 
                            onChange={e => setTerms(e.target.checked)} 
                        />
                        <label>I agree to KLC's Terms of Service, Refunds & Reschedule Policy, and Privacy Policy</label>
                    </div>

                    <div className='checkbox__container'>
                        {/*<input type="checkbox" value={updates} onClick={()=>setUpdates(!updates)} />*/}
                        <input 
                            type="checkbox" 
                            checked={updates} 
                            onChange={e => setUpdates(e.target.checked)} 
                        />
                        <label>Opt in for updates via SMS and email</label>
                    </div>



   
                </div>

        </div>

        <div className='student__button__container'>
            {
                section === "parent" &&
                <button onClick={() => setSection("student")}>
                Next
                </button>
            }

            {
                section === "student" &&
                <div className='captcha__container'>
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        size="invisible" // make it invisible
                        badge="inline" // to control the badge location or use "bottomright" or "bottomleft"
                        onChange={handleTokenChange}
                    />
                    <div className='submit__container'>
                        <AiOutlineRollback onClick={() => setSection("parent")} className='backIcon' size={28} />
                        <button onClick={(e)=>handleSubmitCaptcha(e)} >
                            Submit
                        </button>
                    </div>
                </div>
            }
        </div>
        </div>
    

    </>
  )
}

export default Student