import React, {useState, useEffect} from 'react'
import './body.css';
import Student from '../studentForm/student';
import Teacher from '../teacherForm/teacher';

const Body = ({ type, setType }) => {

  function loadRecaptchaScript() {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
        script.addEventListener('load', resolve);
        script.addEventListener('error', e => reject(`Failed to load reCAPTCHA script: ${e}`));
        document.body.appendChild(script);
    });
  }

  useEffect(() => {
    loadRecaptchaScript()
  }, [])
  return (
    <div className='body'>

        {
            type == "student" &&
            <Student />
        }

        {/*
            type == "teacher" &&
            <Teacher />
        */}


    </div>
  )
}

export default Body