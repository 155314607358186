import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <div className='footer'>
      <p>
      Copyright © 2024 Kids Learn Coding LLC
      </p>
    </div>
  )
}

export default Footer