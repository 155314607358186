import React from 'react';
import './studentSuccess.css';
import Navbar from '../../components/navbar/navbar';
import Footer from '../../components/footer/footer';

const StudentSuccess = () => {
  return (
    <div className='student__success'>
        <Navbar />

        <div className='animation__container'>
            <div class="wrapper"> 
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> 
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/> 
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
            </div>

            <h1>
            Registration Success,<br/>please check your email for confirmation.
            </h1>
        </div>

        <Footer />
    </div>
  )
}

export default StudentSuccess